import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 76 67"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("defs", null, [
      _createElementVNode("linearGradient", {
        id: "TelusGradient",
        x1: "0",
        x2: "1",
        y1: "0",
        y2: "1"
      }, [
        _createElementVNode("stop", {
          offset: "0%",
          "stop-color": "#4B286D"
        }),
        _createElementVNode("stop", {
          offset: "100%",
          "stop-color": "#E53293"
        })
      ])
    ], -1),
    _createElementVNode("path", { d: "M75.049 41.809c3.327-13.55-1.691-29.51-21.137-37.463C34.467-3.608 5.98-2.136 1.017 21.446-3.947 45.03 9.649 67.194 33.383 67c23.734-.195 38.182-10.873 41.666-25.19Z" }, null, -1)
  ])))
}
export default { render: render }